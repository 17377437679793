<template>
  <div class="radio__wrapper">
    <label class="radio__label">
      <input
        v-analytics.click="{
          component: 'option',
          action: 'select',
          step: 'valesaudesempre:rede',
          description: slugify(label),
        }"
        class="s-is-hidden"
        type="radio"
        :name="name"
        :checked="isChecked"
        :value="value"
        @change="$emit('update:modelValue', $event.target.value)"
      />

      <span class="radio__fake-input" aria-hidden="true"></span>

      <b class="m-caption-2">{{ label }}</b>
    </label>
  </div>
</template>

<script>
import { slugify } from "../../../utils/slugify";

export default {
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  props: {
    label: {
      type: String,
      default: "",
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    isChecked() {
      return this.modelValue == this.value;
    },
  },
  methods: {
    slugify,
  },
};
</script>

<style lang="scss">
@use "src/styles/modules/typography/text";
@use "src/styles/base/breakpoints";

.radio {
  &__wrapper {
    input:checked ~ .radio__fake-input,
    input:checked ~ b {
      border-color: var(--color-primary-tint);
      color: var(--color-primary-tint);

      &::after {
        display: block;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;

    cursor: pointer;

    b {
      font-weight: 500;
      color: var(--color-medium-shade);
      transition: color 0.25s ease;
      margin-left: 10px;

      @media (min-width: breakpoints.$size-desktop) {
        @include text.m-text-2;
      }
    }
  }

  &__fake-input {
    display: block;
    width: 22px;
    height: 22px;

    border: 2px solid var(--color-medium-shade);
    border-radius: 50%;

    position: relative;

    &::after {
      content: "";
      display: none;
      position: absolute;
      left: 50%;
      top: 50%;

      transform: translate(-50%, -50%);

      width: 12px;
      height: 12px;

      background-color: var(--color-primary-tint);

      border-radius: 50%;
    }
  }
}
</style>
