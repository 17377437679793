<template>
  <section
    class="l-modal --bottom --enter-btt --clinic-info"
    data-modal="clinic-info"
    hidden
    role="dialog"
    aria-labelledby="clinic-info-title"
    aria-describedby="clinic-info-description"
  >
    <div
      class="m-backdrop"
      data-close="clinic-info"
      :data-focus="'id' + info.id"
      @click="$emit('closeModal')"
    ></div>

    <div class="l-modal__wrapper">
      <article class="l-modal__box s-has-padding-x">
        <header class="modal__header">
          <div class="modal__header-wrapper">
            <i
              class="icon-hospital m-icon-round --icon-primary-tint --icon-default"
              aria-hidden="true"
            ></i>

            <h2
              id="clinic-info-title"
              class="modal__title card__title m-text-3 --color-dark --font-medium"
            >
              {{ info.title }}
            </h2>

            <a
              :href="mapUrl"
              :class="['m-button-primary', { '--disabled': !hasAddressInfo && !hasCoordinates }]"
              target="_blank"
              rel="noreferrer noopener"
              @click="
                sendPopupInteraction({
                  popup_title: slugify(info.title),
                  action: 'click',
                  step: `valesaudesempre:rede:${slugify(info.title)}`,
                  screen_name: screenName,
                  popup_option: `abrir-no-google-maps:${slugify(info.title)}`,
                })
              "
            >
              Abrir Google Maps
            </a>

            <CloseButton
              label="Fechar"
              data-close="clinic-info"
              aria-label="Fechar modal de informações da clínica"
              :data-focus="'id' + info.id"
              @click="
                $emit('closeModal');
                sendPopupInteraction({
                  popup_title: slugify(info.title),
                  action: 'click',
                  step: `valesaudesempre:rede:${slugify(info.title)}`,
                  screen_name: screenName,
                  popup_option: 'fechar',
                });
              "
            />
          </div>
        </header>

        <div id="clinic-info-description" class="modal__content">
          <div class="modal__content-description">
            <h3 class="m-caption-2 --font-medium --color-dark">Endereço</h3>
            <p class="m-caption-1 --font-regular --color-dark-tint">
              {{ info.address || "Não foi possível obter o endereço" }}
            </p>

            <h3 class="m-caption-2 --font-medium --color-dark">Especialidades atendidas</h3>
            <p class="m-caption-1 --font-regular --color-dark-tint">
              {{ info.specialties || "Não foi possível obter as especialidades" }}
            </p>
          </div>

          <div class="modal__map-wrapper m-placeholder">
            <iframe
              v-if="hasCoordinates"
              ref="googleIframe"
              width="100%"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              loading="lazy"
              :data-src="`https://maps.google.com/maps?width=600&amp;height=400&amp;hl=pt-br&amp;q=${info.latitude},${info.longitude}&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`"
              :src="mapsIframeSrc"
            >
            </iframe>

            <div v-else class="modal__map-empty">
              <img
                src="../../../assets/images/service-network/mapa-vazio.svg"
                alt=""
                aria-hidden="true"
                width="696"
                height="352"
                loading="eager"
              />

              <div class="modal__map-empty-content">
                <i class="icon-location-slash" aria-hidden="true"></i>

                <h3 class="m-text-2 --color-dark --font-bold">Não foi possível carregar o mapa.</h3>
              </div>
            </div>
          </div>
        </div>
        <footer class="modal__footer">
          <a
            :class="{ 'm-button-primary': true, '--disabled': !hasAddressInfo && !hasCoordinates }"
            :href="mapUrl"
            target="_blank"
            rel="noreferrer noopener"
            @click="
              sendPopupInteraction({
                popup_title: slugify(info.title),
                action: 'click',
                step: `valesaudesempre:rede:${slugify(info.title)}`,
                screen_name: screenName,
                popup_option: `abrir-no-google-maps:${slugify(info.title)}`,
              })
            "
          >
            Abrir Google Maps
          </a>
        </footer>
      </article>
    </div>
  </section>
</template>

<script>
import { watch } from "vue";
import CloseButton from "../../buttons/CloseButton/index.vue";
import { logEvent } from "../../../services/analytics";
import { slugify } from "../../../utils/slugify";

export default {
  name: "ModalClinicInfo",
  components: {
    CloseButton,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    screenName: {
      type: String,
      default: "",
    },
  },
  emits: ["closeModal"],
  setup(props) {
    watch(
      () => props.info.title,
      (newTitle) => {
        if (newTitle) {
          logEvent("popup_view", {
            popup_title: slugify(newTitle),
            screen_name: props.screenName,
          });
        }
      },
      { immediate: true },
    );
  },
  data() {
    return {
      isOpen: {
        type: Boolean,
        default: false,
      },
      mapsIframeSrc: "",
    };
  },
  computed: {
    hasCoordinates() {
      return this.info.latitude && this.info.longitude;
    },
    hasAddressInfo() {
      return !!this.info.address;
    },
    mapUrl() {
      const { address = "", latitude = "", longitude = "" } = this.info || {};

      const query = address || `${latitude},${longitude}`;

      return `https://www.google.com/maps/search/?api=1&query=${query}`;
    },
  },
  methods: {
    toggleClinicInfoModal(isOpen) {
      if (this.hasCoordinates) {
        this.mapsIframeSrc = isOpen ? this.$refs.googleIframe?.attributes["data-src"]?.value : "";
      }
    },
    sendPopupInteraction(params) {
      logEvent("popup_interaction", params);
    },
    slugify,
  },
};
</script>

<style lang="scss">
@use "src/styles/base/mixins/rem";
@use "src/styles/base/breakpoints";

@use "src/styles/modules/typography/has-limit";
@use "src/styles/modules/typography/subtitle";

@import "./styles/header";
@import "./styles/content";
@import "./styles/map-wrapper";
@import "./styles/footer";

.l-modal.--clinic-info {
  .l-modal {
    &__wrapper,
    &__box {
      width: 100%;
      height: 100%;

      padding: 0;

      @media (min-width: breakpoints.$size-desktop) {
        height: min-content;
      }
    }

    &__wrapper {
      background-color: var(--color-light-tint);

      position: relative;
    }
  }
}
</style>
