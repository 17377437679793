import { createApp } from "vue";
import App from "./App.vue";

import { initErrorLogger } from "./services/errorLogger";

import * as Maska from "maska";
import * as VueRouter from "vue-router";
import analytics from "./directives/analytics";

const ErrorLogger = initErrorLogger();

const routes = [{ path: "/", component: App }];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

const bugsnagVue = ErrorLogger.getPlugin("vue");

const app = createApp(App);

app.use(VueRouter);
app.use(router);
app.use(Maska);
app.use(bugsnagVue);
app.use(analytics);

app.mount("#app");
