<template>
  <div class="search">
    <FormWrapper :is-loading="isLoading" @search="resetSearch($event)" />

    <SearchResults v-if="!!merchants">
      <template v-if="isLoading && page === 1">
        <li v-for="placeholder in [1, 2, 3, 4]" :key="placeholder">
          <CardPlaceholder />
        </li>
      </template>

      <template v-else>
        <li v-if="!isLoading && error">
          <CardError>
            <h3 class="m-text-2 --font-medium --color-dark">Ops, algo deu errado!</h3>

            <template #content>
              <p class="m-text-3 --font-regular --color-dark-tint">
                {{ error }}
              </p>

              <button
                v-analytics.click="{
                  component: 'link',
                  action: 'click',
                  step: 'valesaudesempre:rede',
                  description: 'clique-aqui-para-atualizar',
                }"
                type="button"
                class="m-link --font-medium"
                @click="search(searchTerms)"
              >
                Clique aqui para atualizar
              </button>
            </template>
          </CardError>
        </li>

        <li v-else-if="!merchants.length">
          <CardError>
            <h3 class="m-text-2 --font-medium --color-dark">
              {{
                specialtyLabelSelected
                  ? "Nenhum resultado encontrado para:"
                  : "Nenhum resultado encontrado."
              }}
            </h3>

            <template #content>
              <p v-if="specialtyLabelSelected" class="m-text-3 --font-regular --color-dark-tint">
                {{ specialtyLabelSelected }}, nessa região.
              </p>
            </template>
          </CardError>
        </li>

        <template v-else>
          <li v-for="(merchant, index) in merchants" :id="'merchant-' + index" :key="index">
            <CardDefault>
              <h3 class="card__title m-text-2 --color-dark --font-medium">
                {{ merchant.name }}
              </h3>

              <template #header-end>
                <ItemLink
                  v-analytics.click="{
                    component: 'button',
                    action: 'click',
                    step: `${getEventStep}`,
                    description: `mais-informacoes:${slugify(merchant.name)}`,
                  }"
                  data-open="clinic-info"
                  @click="handleToggleModal(`#merchant-${index}`, merchant, true)"
                >
                  Mais informações
                </ItemLink>
              </template>

              <template #footer>
                <div class="card__footer-1">
                  <h4 class="m-caption-2 --font-medium --color-dark">Especialidade</h4>

                  <ItemLink
                    v-if="isAllSpecialties"
                    data-open="clinic-info"
                    @click="handleToggleModal(`#merchant-${index}`, merchant, true)"
                  >
                    {{ formatSpecialtyText(merchant.specialties?.length) }}
                  </ItemLink>

                  <p v-else class="m-caption-1 --font-regular --color-dark-tint">
                    {{ getSpecialtyByType }}
                  </p>
                </div>

                <div class="card__footer-2">
                  <h4 class="m-caption-2 --font-medium --color-dark">Endereço</h4>
                  <p class="m-caption-1 --font-regular --color-dark-tint">
                    {{ merchant.address_html }}
                  </p>
                </div>

                <a
                  v-analytics.click="{
                    component: 'link',
                    action: 'click',
                    step: `${getEventStep}`,
                    description: `ver-mapa:${slugify(merchant.name)}`,
                  }"
                  class="m-button-sm justify-end"
                  :class="{
                    '--disabled': !hasCoordinates(merchant),
                  }"
                  :href="`https://www.google.com/maps/search/?api=1&query=${merchant.address_html}`"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i class="icon-map --icon-small" aria-hidden="true"></i>
                  Ver mapa
                </a>
              </template>
            </CardDefault>
          </li>
        </template>
      </template>

      <template v-if="shouldDisplayOnlineCard" #online-results>
        <li
          v-for="(merchant, index) in [{ name: 'Consulta Online via Telemedicina' }]"
          :id="'merchant-' + index"
          :key="index"
        >
          <CardDefault
            :hasColoredBackground="true"
            :icon="{ name: 'icon-video', color: '--color-primary-shade' }"
          >
            <h3 class="card__title m-text-2 --color-dark --font-medium">
              {{ merchant.name }}
            </h3>

            <template #header-end>
              <div class="justify-end s-is-hidden-mobile">
                <ItemIcon :icon="{ name: 'icon-mobile', color: '--color-primary-tint ' }">
                  <a
                    class="m-caption-2 --font-medium --color-primary-shade is-clickable"
                    data-testid="new-tab-schedule"
                    href="https://app.valesaudesempre.com.br/solicitar-agendamento"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Pelo aplicativo no conforto da sua casa
                  </a>
                </ItemIcon>
              </div>
            </template>

            <template #footer>
              <div class="card__footer-1">
                <h4 class="m-caption-2 --font-medium --color-dark">Especialidade</h4>

                <p class="m-caption-1 --font-regular --color-dark-tint">
                  {{ getSpecialtyByType }}
                </p>
              </div>

              <div class="justify-end s-is-hidden-desktop">
                <ItemIcon :icon="{ name: 'icon-mobile', color: '--color-primary-tint ' }">
                  <a
                    class="m-caption-2 --font-medium --color-primary-shade is-clickable"
                    href="https://app.valesaudesempre.com.br/solicitar-agendamento"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Pelo aplicativo no conforto da sua casa
                  </a>
                </ItemIcon>
              </div>
            </template>
          </CardDefault>
        </li>
      </template>

      <template v-if="merchants" #footer>
        <template v-if="hasNextPage">
          <Spacer mobile="48px" desktop="72px" />

          <button
            v-analytics.click="{
              component: 'button',
              action: 'click',
              step: `${getEventStep}`,
              description: 'carregar-mais',
            }"
            :class="{ 'm-button-primary': true, '--is-loading': isLoading }"
            type="button"
            data-testid="load-more"
            @click="loadMore"
          >
            Carregar mais

            <Spinner />
          </button>
        </template>

        <button
          v-analytics.click="{
            component: 'button',
            action: 'click',
            step: `${getEventStep}`,
            description: 'voltar-ao-topo',
          }"
          class="search-results__footer-load-more"
          type="button"
          aria-label="Voltar ao topo da página para buscar novamente"
          @click="scrollToAndFocus('buscar')"
        >
          <ButtonIconOnly>
            <i class="icon-full-arrow-up" aria-hidden="true"></i>
          </ButtonIconOnly>
        </button>
      </template>
    </SearchResults>
  </div>

  <ModalClinicInfo
    ref="clinicModalInfo"
    :info="modalInfo"
    :screenName="screenName"
    :is-open="isClinicInfoModalOpen"
    @close-modal="toggleClinicInfoModal(false)"
  />
</template>

<script>
import api from "../../services/api";
import { errorLoggerNotify } from "../../services/errorLogger";
import { initModals } from "../../utils/modals";

import { searchType } from "../../types/searchType";

import Spacer from "../../components/Spacer/index.vue";
import Spinner from "../../components/Spinner/index.vue";
import ButtonIconOnly from "../../components/buttons/IconOnly/index.vue";
import CardDefault from "../../components/cards/Default/index.vue";
import CardPlaceholder from "../../components/cards/Default/placeholder/index.vue";
import CardError from "../../components/cards/Error/index.vue";
import ItemIcon from "../../components/items/ItemIcon/index.vue";
import ItemLink from "../../components/items/ItemLink/index.vue";
import ModalClinicInfo from "../../components/modals/ClinicInfo/index.vue";
import { logEvent } from "../../services/analytics";
import { slugify } from "../../utils/slugify";
import FormWrapper from "./FormWrapper/index.vue";
import SearchResults from "./SearchResults/index.vue";

export default {
  components: {
    Spacer,
    FormWrapper,
    Spinner,
    SearchResults,
    CardDefault,
    CardError,
    CardPlaceholder,
    ModalClinicInfo,
    ButtonIconOnly,
    ItemLink,
    ItemIcon,
  },
  data() {
    return {
      modalInfo: {
        id: "",
        title: "",
        address: "",
        specialties: "",
        latitude: "",
        longitude: "",
      },
      isLoading: false,
      isClinicInfoModalOpen: false,
      page: 1,
      merchants: undefined,
      shouldDisplayOnlineCard: false,
      error: "",
      searchTerms: {},
      pagination: {},
      screenName: "valesaudesempre:rede",
    };
  },
  computed: {
    specialtyLabelSelected() {
      return this.searchTerms?.type === searchType.PostalCode
        ? this.searchTerms?.terms?.zip_specialty_label
        : this.searchTerms?.terms?.specialty_label;
    },
    hasNextPage() {
      return !!this.pagination.links?.next;
    },
    isAllSpecialties() {
      const { terms = {}, type = 0 } = this.searchTerms;

      const specialty =
        type === searchType.PostalCode ? terms.zip_specialty_label : terms.specialty_label;

      return !specialty || specialty === "Todas as especialidades";
    },
    getSpecialtyByType() {
      const { terms = {}, type = 0 } = this.searchTerms;

      const specialty =
        type === searchType.PostalCode ? terms.zip_specialty_label : terms.specialty_label;

      return specialty;
    },
    getEventStep() {
      return this.searchTerms?.type === searchType.PostalCode
        ? "rede:pesquisa-por-cep-e-especialidade"
        : "rede:pesquisa-por-estado-cidade-e-especialidade";
    },
  },
  methods: {
    resetSearch(data) {
      this.page = 1;
      this.merchants = [];
      this.shouldDisplayOnlineCard = false;

      this.search(data);
    },
    async search(data) {
      if (!data) return;

      this.searchTerms = data;

      this.isLoading = true;

      this.error = "";

      if (!this.merchants) this.merchants = [];

      if (this.page === 1) {
        await this.$nextTick();
        this.scrollToAndFocus("resultados-de-busca");
      }

      const { type = "", terms = {} } = data;
      const { specialty = "", zip_specialty = "", zip_code = "", uf = "", city = "" } = terms;

      let params = {
        page: this.page,
        per_page: 10,
        specialty: specialty === "all" ? "" : specialty,
      };

      let typeParams = {};

      if (type === searchType.PostalCode) {
        typeParams = { zipcode: zip_code, specialty: zip_specialty === "all" ? "" : zip_specialty };
      } else {
        typeParams = {
          uf,
          city,
        };
      }

      params = {
        ...params,
        ...typeParams,
      };

      try {
        const res = await api.get("/v1/general/lists/merchants", { params });
        this.merchants = this.page === 1 ? res.data : this.merchants.concat(res.data);
        this.shouldDisplayOnlineCard = res.is_telemedicine_specialty;
        this.pagination = res.pagination;

        if (!this.merchants.length) {
          logEvent("exception", {
            description: "nenhum-resultado-encontrado",
            fatal: false,
          });
        }
      } catch (e) {
        const message = "Por algum motivo, não conseguimos carregar os resultados 😓";
        this.error = message;

        logEvent("exception", {
          description: "ops-algo-deu-errado",
          fatal: false,
        });

        erroLoggerNotify(new Error("Erro ao buscar dados na Rede de atendimento"), (ev) => {
          ev.severity = "error";
          ev.addMetadata("error", e);
          ev.addMetadata("apiRoute", { apiRoute: "/v1/general/lists/merchants" });
          ev.addMetadata("params", params);
        });
      } finally {
        this.isLoading = false;
        await this.$nextTick();
        initModals();
      }
    },
    async loadMore() {
      if (!this.isLoading && !!this.pagination.links?.next) {
        this.page++;
        return this.search(this.searchTerms);
      }
    },
    updateModalInfo(id = "", info = {}) {
      const formattedInfo = {
        id: info.id,
        title: info.name,
        address: info.address_html,
        specialties: info.specialties_html,
        latitude: info.address?.latitude,
        longitude: info.address?.longitude,
      };

      this.modalInfo = {
        id,
        ...formattedInfo,
      };

      this.validateModalInfoAndSendReport(formattedInfo);
    },
    toggleClinicInfoModal(isOpen) {
      this.isClinicInfoModalOpen = isOpen;
      this.$refs.clinicModalInfo.toggleClinicInfoModal(isOpen);
    },
    async handleToggleModal(id = "", info = {}, isOpen = false) {
      this.updateModalInfo(id, info);
      await this.$nextTick();
      this.toggleClinicInfoModal(isOpen);
    },
    scrollToAndFocus(id) {
      const searchSection = document.getElementById(id);
      searchSection.scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        searchSection.focus();
      }, 1000);
    },
    formatSpecialtyText(specialtiesLength) {
      return specialtiesLength > 1
        ? `${specialtiesLength} especialidades`
        : `${specialtiesLength} especialidade`;
    },
    validateModalInfoAndSendReport(info = {}) {
      let invalidInfo = [];

      Object.keys(info).forEach((key) => {
        if (!info[key]) {
          invalidInfo.push({ key, value: info[key] });
        }
      });

      if (!invalidInfo.length) {
        return;
      }

      errorLoggerNotify(
        {
          name: "Warning",
          message: `Ao abrir o modal da clínica ${info.id}-${info.title}`,
        },
        (event) => {
          event.context = "Clínica com dados inconsistentes";
          event.addMetadata("info", info);
        },
      );
    },
    hasCoordinates(item) {
      return (item?.address?.latitude && item?.address?.longitude) || item.address_html;
    },
    slugify,
  },
};
</script>

<style lang="scss">
@use "src/styles/base/breakpoints";

.search {
  position: relative;

  width: 100%;
  max-width: breakpoints.$size-desktop;

  margin: 0 auto;
  padding: 0 20px 48px;

  @media (min-width: breakpoints.$size-desktop) {
    padding-bottom: 140px;
  }
}

.is-clickable {
  cursor: pointer;
}
</style>
