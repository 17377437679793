export const getPlatform = () => {
  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return "web";
};

export const isMobile = () => {
  return getPlatform() !== "web";
};
