export const slugify = (value) => {
  if (!value) {
    return "";
  }

  value = value.replace(/^\s+|\s+$/g, ""); // Trim
  value = value.toLowerCase();

  // Substituição dos acentos
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  const to = "aaaaaeeeeeiiiiooooouuuunc----:-";
  for (var i = 0, l = from.length; i < l; i++) {
    value = value.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  value = value
    .replace(/[^a-z0-9 :-]/g, "") // Remove caracteres inválidos
    .replace(/\s+/g, "-") // Transforma espaços em hífens
    .replace(/-+/g, "-"); // Ajusta os hífens existentes

  return value;
};
