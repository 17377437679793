<script setup>
import Banner from "./layouts/Banner/index.vue";
import HeaderLogo from "./layouts/HeaderLogo/index.vue";
import Partners from "./layouts/Partners/index.vue";
import ServiceCoverage from "./layouts/ServiceCoverage/index.vue";

import Search from "./layouts/Search/index.vue";
import { sendPageView } from "./services/analytics";

sendPageView("/valesaudesempre/rede");
</script>

<template>
  <HeaderLogo />
  <Banner />
  <Search />
  <ServiceCoverage />
  <Partners />
</template>

<style lang="scss">
@import "./styles/main.scss";
</style>
