import { logEvent } from "../../services/analytics";

export default {
  install(app) {
    app.directive("analytics", {
      mounted(el, binding) {
        const { value, modifiers } = binding;

        if (modifiers.click) {
          el.addEventListener("click", () => {
            logEvent("user_interaction", value);
          });
        }
      },
      unmounted(el, binding) {
        const { modifiers } = binding;

        if (modifiers.click) {
          el.removeEventListener("click", binding.value);
        }
      },
    });
  },
};
